import React from 'react';

const Blog = () => {
    return (
        <div className='font-bold text-4xl flex justify-center items-center h-screen'>
            Comming sooooooooooooooooon
        </div>
    );
};

export default Blog;